import React, { useEffect, useRef, useState } from 'react';
import { OpenAPI, OpenAPIConfig } from '../gen/addressSearchClient/core/OpenAPI';
import { StreetAddressService } from '../gen/addressSearchClient/services.gen';
import { $AddressInfoByTemplateResponse } from '../gen/addressSearchClient/schemas.gen';
import { addressConfig, storageConfig, loginRequest } from '../authConfig';
import { AddressInfoByTemplateResponse } from '../gen/addressSearchClient/types.gen';
import { Alert, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import PointsOnAddress from '../ui-components/PointsOnAddress';
import SearchBox from '../ui-components/SearchBox';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { msalInstance } from "../index";
import { fetchApiKey } from '../utils/MsGraphApiCall';

OpenAPI.WITH_CREDENTIALS = false
OpenAPI.BASE = addressConfig.baseUrl;
OpenAPI.HEADERS = {}

OpenAPI.interceptors.response.use((response) => {
  if (response.status === 200) {
    console.log(`request to ${response.url} succeeded`)
  }
  return response;
});

export function setupInterceptor(apiKey) {
  OpenAPI.interceptors.request.use((request) => {
    console.log(`request to ${request} started`)
    if (request.headers) {
      request.headers['X-Edig-Api-Key'] = apiKey;
      console.log(`setting api key on request ${apiKey}`)
    } else {
      console.log(`request headers is null`)
    }
    return request;
  });
}

export function Addresses() {

  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef<HTMLInputElement>(null); // Create a ref
  const [searchResult, setSearchResult] = useState<AddressInfoByTemplateResponse | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState<string | null>(null);

  useEffect(() => {
    inputRef.current?.focus();

    fetchApiKey(msalInstance, loginRequest, storageConfig, setApiKey);

  }, [msalInstance])


  useEffect(() => {
    if (apiKey) {
      console.log(`setting interceptor with api key ${apiKey}`);
      setupInterceptor(apiKey);
    } else {
      console.log(`api key is null`);
    }
  }, [apiKey]); // Only run the effect if apiKey changes

  const handleSearch = async () => {
    setLoading(true);
    setErrorMessage(null);
    try {
      setSearchResult(null); // set seach result to null before search
      const inputValue = (
        (inputRef.current?.value || '')
          .split(' ')
          .map(word => word.toUpperCase())
          .join(' ')
      );
      console.log(`handle search, input ${inputValue}, `)

      OpenAPI.HEADERS = {}
      OpenAPI.HEADERS['X-Edig-Api-Key'] = apiKey;


      const response = await StreetAddressService.getApiV1StreetAddressByAddressTemplate({ addressTemplate: inputValue, includeLetters: true }) as AddressInfoByTemplateResponse;
      console.log(`search response ${response}`)
      setSearchResult(response?.addressInfo || null); // Provide a default value of null if response?.addressInfo is undefined
      setErrorMessage(null);

    } catch (error) {
      console.log(`Error: ${error} `)
      setErrorMessage(`Error: ${error} `);
    } finally {
      setLoading(false);
    }

  };

  return (
    <div>
      <h1>Adressesøk</h1>
      <AuthenticatedTemplate>
        <SearchBox
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleSearch={handleSearch}
          inputRef={inputRef}
        />
      </AuthenticatedTemplate>
      {loading ? <CircularProgress /> : null}
      {errorMessage && (
        <Alert severity="error">{errorMessage}</Alert>
      )}
      {searchResult && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Id</TableCell>
                <TableCell >Gate</TableCell>
                <TableCell >Postnr.</TableCell>
                <TableCell >Postadr.</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResult.map((adr, index) => (
                <PointsOnAddress key={index} addressId={adr.addressId} streetAddress={adr.streetAddress} postalNumber={adr.postalNumber} postalPlace={adr.postalPlace} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      )}
    </div>
  );
};

export default Addresses;