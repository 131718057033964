import React from 'react';
import { Tooltip } from '@mui/material'; // Make sure to import Tooltip
import { ErrorOutline, CheckCircleOutline, Unpublished, Pending, Sync, AddCircle, ChangeCircle, RemoveCircle, Engineering, LinearScale } from '@mui/icons-material';
import { ConfigureResponse } from '../gen/cfsInventoryClient/types.gen';

export const StatusIconConfigResponse = ({ configResponse, suppressToolTip }: { configResponse: ConfigureResponse, suppressToolTip?: boolean }) => {
    let icon = <Unpublished sx={{ color: 'rgb(200, 200, 200)' }} fontSize="small" />;;
    let toolTipTitle = 'Unknown';
    switch (configResponse.status) {
        case 'COMPLETED':
            icon = <CheckCircleOutline sx={{ color: 'rgb(0, 200, 0)' }} fontSize="small" />;
            toolTipTitle = 'Completed';
            break;
        case 'FAILED':
            icon = <ErrorOutline sx={{ color: 'rgb(200, 0, 0)' }} fontSize="small" />;
            toolTipTitle = 'Failed';
            break;
        case 'WORKING':
            icon = <Sync sx={{ color: 'rgb(0, 0, 200)' }} fontSize="small" />;
            toolTipTitle = 'Working';
            break;
        case 'ACCEPTED':
            icon = <Pending sx={{ color: 'rgb(255, 216, 0)' }} fontSize="small" />;
            toolTipTitle = 'Accepted';
            break;
        case 'PENDING':
            icon = <Pending sx={{ color: 'rgb(255, 216, 0)' }} fontSize="small" />;
            toolTipTitle = 'Pending';
            break;
        case 'INITIALIZED':
            icon = <Pending sx={{ color: 'rgb(255, 216, 0)' }} fontSize="small" />;
            toolTipTitle = 'Initialized';
            break;
    }
    if (!suppressToolTip) {
        return (
            <Tooltip title={toolTipTitle}>
                {icon}
            </Tooltip>
        );
    } else {
        return icon;
    }
}

export const StatusIconConfigResponsesAggregated = ({ configResponses }: { configResponses: ConfigureResponse[] | undefined }) => {
    if (!configResponses) return null;

    // Initialize a map to hold counts for each status type
    const statusCounts = configResponses.reduce((acc, cr) => {
        if (cr.status !== undefined) {
            acc[cr.status] = (acc[cr.status] || 0) + 1;
        }
        return acc;
    }, {});

    // Function to generate tooltip based on status and count
    const generateToolTip = (status: string, count: number) => {
        if (count === 1) {
            const singleResponse = configResponses.find(cr => cr.status === status);
            const templateName = singleResponse?.template_name ?? 'Unknown Template';
            return `${status}: ${templateName}`;
        } else {
            return `${status}: ${count} Templates`;
        }
    };

    const statusOrder = ["FAILED", "UNSPECIFIED", "INITIALIZED", "ACCEPTED", "PENDING", "WORKING"];

    // Sort the entries of statusCounts based on the defined order
    const sortedStatusCounts = Object.entries(statusCounts).sort(
        (a, b) => statusOrder.indexOf(a[0]) - statusOrder.indexOf(b[0])
    );

    // Accumulate the JSX elements for each status
    const icons = sortedStatusCounts.map(([status, countString]) => {
        const count = parseInt(countString);
        const toolTip = generateToolTip(status, count);
        const cr: ConfigureResponse = { status: status as "UNSPECIFIED" | "ACCEPTED" | "PENDING" | "WORKING" | "COMPLETED" | "INITIALIZED" | "FAILED" | undefined };
        return (
            <Tooltip title={toolTip} key={status}>
                <div style={{ display: 'inline-flex' }}>
                    <StatusIconConfigResponse configResponse={cr} suppressToolTip={true} />
                </div>
            </Tooltip>
        );
    });
    console.log('icons:', icons);

    // Return the accumulated JSX elements
    // If there are no statuses to display, return a default icon or null
    return icons.length > 0 ? <>{icons}</> : <StatusIconConfigResponse configResponse={{}} />;
};

export const ChangeNatureIcon = ({ configResponse, suppressToolTip }: { configResponse: ConfigureResponse, suppressToolTip?: boolean }) => {
    let icon = <Unpublished sx={{ color: 'rgb(200, 200, 200)' }} fontSize="small" />;;
    let toolTipTitle = 'Unknown';
    const color = 'rgb(200, 200, 200)'; // grey
    switch (configResponse.change_nature) {
        case 'SETUP':
            icon = <AddCircle sx={{ color }} fontSize="small" />;
            toolTipTitle = 'Setup';
            break;
        case 'MODIFICATION':
            icon = <ChangeCircle sx={{ color }} fontSize="small" />;
            toolTipTitle = 'Modification';
            break;
        case 'REMOVE':
            icon = <RemoveCircle sx={{ color }} fontSize="small" />;
            toolTipTitle = 'Remove';
            break;
        case 'MAINTENANCE':
            icon = <Engineering sx={{ color }} fontSize="small" />;
            toolTipTitle = 'Maintenance';
            break;
        case 'NOCHANGE':
            icon = <LinearScale sx={{ color }} fontSize="small" />;
            toolTipTitle = 'No Change';
            break;
    }
    if (!suppressToolTip) {
        return (
            <Tooltip title={toolTipTitle}>
                {icon}
            </Tooltip>
        );
    } else {
        return icon;
    }
}
