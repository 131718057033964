import React, { useEffect } from "react"
import { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Typography from "@mui/material/Typography";
import { OpenAPI } from "../gen/cfsInventoryClient/core/OpenAPI"
import { $CFPointResponse } from "../gen/cfsInventoryClient/schemas.gen";
import { CfsInventoryPointsService } from "../gen/cfsInventoryClient/services.gen"
import { CfsInventoryPointsGetPointsResponse, CfsInventoryPointsGetPointsData } from "../gen/cfsInventoryClient/types.gen";
import { addAuthorizationHeader, acquireAccessToken } from "../utils/auth";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import { inventoryConfig } from "../authConfig";


OpenAPI.BASE = inventoryConfig.baseUrl;

OpenAPI.interceptors.response.use((response) => {
  if (response.status === 200) {
    console.log(`request to ${response.url} succeeded`)
  }
  return response;
});

OpenAPI.interceptors.request.use(addAuthorizationHeader);

export function Points() {

  const [page, setPage] = useState(0);
  const rowsPerPage = 40;
  const [pointList, setPoints] = useState<CfsInventoryPointsGetPointsResponse | null>(null);
  const onFetchPoints = async (page: number) => {
    const input: CfsInventoryPointsGetPointsData = {
      limit: rowsPerPage,
      offset: page * rowsPerPage
    }

    OpenAPI.TOKEN = await acquireAccessToken(inventoryConfig.scope);

    const response = await CfsInventoryPointsService.cfsInventoryPointsGetPoints(input) as CfsInventoryPointsGetPointsResponse
    setPoints(response?.points);
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    onFetchPoints(newPage);
  };
  useEffect(() => {
    onFetchPoints(0)
  }, [])
  return (
    <div>
      <Typography variant="h6" align="center">Points</Typography>
      <AuthenticatedTemplate>

        {pointList && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Point ID</TableCell>
                  <TableCell >Point Name</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {pointList.map((point) => (
                  <TableRow key={point.id}>
                    <TableCell component="th" scope="row">
                      {point.id}
                    </TableCell>
                    <TableCell >{point.display_name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={-1}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[rowsPerPage]}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}

      </AuthenticatedTemplate><UnauthenticatedTemplate>
        <Typography variant="h6" align="center">Logg inn for å se ting og tang</Typography>
      </UnauthenticatedTemplate></div>
  );
}