import React, { useEffect } from "react"
import { useState } from "react";
import { OpenAPI } from '../../gen/ipamClient/core/OpenAPI.ts';
import { AddressSpaceService } from '../../gen/ipamClient/services.gen';
import { ipamConfig } from "../../authConfig.ts";
import { acquireAccessToken } from "../../utils/auth.ts";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from "@mui/material";
import { Link } from "react-router-dom";

OpenAPI.BASE = ipamConfig.baseUrl;
//OpenAPI.TOKEN = await acquireAccessToken(ipamConfig.scope);

OpenAPI.interceptors.response.use((response) => {
  if (response.status === 200) {
    console.log(`request to ${response.url} was successful`);
  }
  return response;
});

export function Addressspaces() {
  const [addressSpaces, setAddressSpaces] =
    useState<Awaited<ReturnType<typeof AddressSpaceService.getAddressspaces>>>();

  const onFetchAddressSpaces = async () => {
    OpenAPI.TOKEN = await acquireAccessToken(ipamConfig.scope);
    const addressSpaces = await AddressSpaceService.getAddressspaces();
    setAddressSpaces(addressSpaces);
  };
  useEffect(() => {
    onFetchAddressSpaces()
  }, [])
  return (
    <div>
      <Typography variant="h6" align="center">Addressspaces</Typography>
      <AuthenticatedTemplate>

        {addressSpaces && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell >Addressspace</TableCell>
                  <TableCell >IPv4/IPv6</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addressSpaces.address_spaces?.map((addressspace) => (
                  <TableRow component={Link} to={`/ipam/addressspace/${addressspace.id}`} key={addressspace.id}>
                    <TableCell >{addressspace.address}</TableCell>
                    <TableCell >Ipv{addressspace.version}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>

              </TableFooter>
            </Table>
          </TableContainer>
        )}

      </AuthenticatedTemplate><UnauthenticatedTemplate>
        <Typography variant="h6" align="center">Logg inn for å se ting og tang</Typography>
      </UnauthenticatedTemplate></div>
  );
}

