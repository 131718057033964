import React, { useState } from 'react';
import { CircularProgress, TableCell, TableRow } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { acquireAccessToken } from '../utils/auth';
import { OpenAPI } from '../gen/cfsInventoryClient/core/OpenAPI';
import { CfEquipmentsOnPointResponse, CfResourceEquipment, CfsInventoryPointsGetEquipmentsOnPointData, CfsInventoryPointsGetEquipmentsOnPointResponse } from '../gen/cfsInventoryClient/types.gen';
import { CfsInventoryPointsService } from '../gen/cfsInventoryClient/services.gen';
import StorageIcon from '@mui/icons-material/Storage';
import Equipment from './Equipment';
import PlaceIcon from '@mui/icons-material/Place';


interface PointProps {
  key: string;
  id: string;
  display_name: string;
}

const Point: React.FC<PointProps> = ({ id, display_name }) => {
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [equipmentList, setEquipmentList] = useState<Array<CfResourceEquipment> | null>(null);

  const handleIconClick = async () => {
    setLoading(true);
    setExpanded(true);
    console.log(`Click on point ${id}`);
    try {

      const token = await acquireAccessToken();
      OpenAPI.TOKEN = token;
      const request: CfsInventoryPointsGetEquipmentsOnPointData = {
        pointId: id
      }
      const response = await CfsInventoryPointsService.cfsInventoryPointsGetEquipmentsOnPoint(request) as CfEquipmentsOnPointResponse;
      console.log(response);
      setEquipmentList(response?.resource_equipments);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TableRow key={id} style={{ backgroundColor: '#f0f0f0' }}>
        <TableCell style={{ paddingLeft: '10px' }}>
          {loading ? <CircularProgress /> : null}
          {expanded && !loading ? <ExpandLessIcon onClick={() => setExpanded(false)} /> : <ExpandMoreIcon onClick={handleIconClick} />}
        </TableCell>
        <TableCell style={{ paddingLeft: '10px' }}>
          <PlaceIcon />
        </TableCell>
        <TableCell>
          {display_name}
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
      {equipmentList && expanded && (
        <>
          {equipmentList.map((equipment) => (
            <Equipment key={equipment.id} id={equipment.id} />
          ))}
        </>

      )}
    </>
  );
};

export default Point;