import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import IconButton from '@mui/material/IconButton';
import AccountCircle from "@mui/icons-material/AccountCircle";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import React from "react";
import { useEffect } from "react";
import { getProfileImage } from "../utils/MsGraphApiCall";
import { loginRequest } from "../authConfig";
import Avatar from '@mui/material/Avatar';

const UserImage = () => {
    const { instance, inProgress } = useMsal();
    const [base64Image, setBase64Image] = useState<string | null>(null);


    useEffect(() => {
        if (!base64Image && inProgress === InteractionStatus.None) {
            getProfileImage().then(blob => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setBase64Image(reader.result as string);
                }
                reader.readAsDataURL(blob);
            }
            ).catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount() as AccountInfo
                    });
                }
            });

        }
    }, [base64Image]);

    return (
        <div>
            {base64Image ? <Avatar src={`${base64Image}`} /> : null}
        </div>
    );
};

export const SignOutButton = () => {
    const { instance } = useMsal();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleLogout = (logoutType: string) => {
        setAnchorEl(null);

        if (logoutType === "popup") {
            instance.logoutPopup({
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect();
        }
    }


    return (
        <div>
            <IconButton
                onClick={(event) => setAnchorEl(event.currentTarget)}
                color="inherit"
            >
                <UserImage />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => handleLogout("popup")} key="logoutPopup">Logout using Popup</MenuItem>
                <MenuItem onClick={() => handleLogout("redirect")} key="logoutRedirect">Logout using Redirect</MenuItem>
            </Menu>
        </div>
    )
};