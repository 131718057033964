import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID || '915a705b-2a6f-40a4-a749-ae8135dc2c53',
        authority: process.env.REACT_APP_AUTHORITY || 'https://login.microsoftonline.com/12f1bdca-9eec-45f6-a63e-2061b957e8ee',
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me?$select=onPremisesSamAccountName,officeLocation,department,companyName,displayName,userPrincipalName,jobTitle,mobilePhone",
    profileImage: "https://graph.microsoft.com/v1.0/me/photos/48x48/$value"
};

export const inventoryConfig = {
    scope: process.env.REACT_APP_INVENTORY_SCOPE || ["api://0b06ab06-a2e0-439d-9190-bdab1f28c285/Inventory.Manage"],
    baseUrl: process.env.REACT_APP_INVENTORY_BASE_URL || "https://api-gw.eviny.no/cfsInventory/pa"
}

export const ipamConfig = {
    //scope: ["api://01bc2efb-ef12-435d-b089-ac1a578d1cba/Ipam.All"], // DEV

    scope: ["api://86c4aadc-6711-45d8-80ec-d4f82db29e34/Ipam.All"], // PROD
    //baseUrl: "https://digital.eviny.io/dev/ipam" // DEV
    // baseUrl: "https://digital.eviny.io/ipam" // PROD
    baseUrl: "https://api-gw.eviny.no/ipam/v1.2.1"
}

export const addressConfig = {
    apiKey: process.env.REACT_APP_ADDRESS_API_KEY || '',
    baseUrl: process.env.REACT_APP_SEARCH_BASE_URL || "https://api-gw.eviny.no/addressSearch/pa"
}

export const storageConfig = {
    scope: [
        process.env.REACT_APP_STORAGE_SCOPE || "https://corpportalconfigsa.blob.core.windows.net/.default"
    ],
    baseUrl: process.env.REACT_APP_STORAGE_URL || "https://corpportalconfigsa.blob.core.windows.net/"
}
