import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "./SignInSignOutButton";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { Height } from "@mui/icons-material";

const NavBar = () => {
    return (
        <div style={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{ backgroundColor: 'rgb(18, 68, 66)' }} >
                <Toolbar>
                    <Typography style={{ flexGrow: 1 }}>
                        <Link
                            component={RouterLink}
                            to="/"
                            color="inherit"
                            variant="h6"
                            underline="none"
                        >
                            <img src={process.env.PUBLIC_URL + '/eviny-logo.svg'} alt="Hjem" height="32" width="32" />
                        </Link>
                    </Typography>
                    <Typography style={{ flexGrow: 1 }}>
                        <Link
                            component={RouterLink}
                            to="/profile"
                            color="inherit"
                            variant="h6"
                            underline="none"
                        >
                            Meg
                        </Link>
                    </Typography>
                    <Typography style={{ flexGrow: 1 }}>
                        <Link
                            component={RouterLink}
                            to="/points"
                            color="inherit"
                            variant="h6"
                            underline="none"
                        >
                            Punkt
                        </Link>
                    </Typography>
                    <Typography style={{ flexGrow: 1 }}>
                        <Link
                            component={RouterLink}
                            to="/addresses"
                            color="inherit"
                            variant="h6"
                            underline="none"
                        >
                            Adresser
                        </Link>
                    </Typography>
                    <Typography style={{ flexGrow: 1 }}>
                        <Link
                            component={RouterLink}
                            to="/services/"
                            color="inherit"
                            variant="h6"
                            underline="none"
                        >
                            Tjenester
                        </Link>
                    </Typography>

                    <WelcomeName />
                    <SignInSignOutButton />
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default NavBar;