import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  Box,
  CircularProgress,
  Divider,
  TableCell,
  TableRow,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { acquireAccessToken } from "../utils/auth";
import { OpenAPI } from "../gen/cfsInventoryClient/core/OpenAPI";
import {
  CfEquipmentsOnPointResponse,
  CfResourceEquipment,
  CfResourceEquipmentPort,
  CfsInventoryPointsGetEquipmentsOnPointData,
  CfsInventoryPointsGetEquipmentsOnPointResponse,
  CfsInventoryResourceEquipmentsGetExternalSystemByResourceEquipmentIdAndSystemNameData,
  CfsInventoryResourceEquipmentsGetMgmtEquipmentByResourceEquipmentData,
  CfsInventoryResourceEquipmentsGetResourceEquipmentByIdData,
  GetResourceEquipmentByIdResponse,
  GetMgmtEquipmentByResourceEquipmentResponse,
  ResourceEquipment,
  ResourceEquipmentPort,
  MgmtEquipment,
} from "../gen/cfsInventoryClient/types.gen";
import {
  CfsInventoryMgmtEquipmentsService,
  CfsInventoryPointsService,
  CfsInventoryResourceEquipmentsService,
} from "../gen/cfsInventoryClient/services.gen";
import StorageIcon from "@mui/icons-material/Storage";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import LanIcon from "@mui/icons-material/Lan";

interface EquipmentProps {
  id: string;
}

const getIconColor = (state) => {
  switch (state) {
    case "RESERVED":
      return "blue";
    case "UNUSED":
      return "green";
    default:
      return "gray";
  }
};

const Equipment: React.FC<EquipmentProps> = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [portList, setPortList] = useState<Array<ResourceEquipmentPort> | null>(
    null,
  );
  const [resourceEquipment, setResourceEquipment] =
    useState<ResourceEquipment | null>(null);
  const [mgmtEquipment, setMgmtEquipment] = useState<MgmtEquipment | null>(
    null,
  );

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const token = await acquireAccessToken();
        OpenAPI.TOKEN = token;
        const request: CfsInventoryResourceEquipmentsGetResourceEquipmentByIdData =
          {
            id: id,
          };
        const response =
          (await CfsInventoryResourceEquipmentsService.cfsInventoryResourceEquipmentsGetResourceEquipmentById(
            request,
          )) as GetResourceEquipmentByIdResponse;
        console.log(JSON.stringify(response));
        setResourceEquipment(response?.resource_equipment);
        setPortList(response?.resource_equipment?.ports);

        const mgmtEquipmentRequest: CfsInventoryResourceEquipmentsGetMgmtEquipmentByResourceEquipmentData =
          {
            resourceEquipmentId: id,
          };
        const mgmtEquipmentResponse =
          (await CfsInventoryResourceEquipmentsService.cfsInventoryResourceEquipmentsGetMgmtEquipmentByResourceEquipment(
            mgmtEquipmentRequest,
          )) as GetMgmtEquipmentByResourceEquipmentResponse;
        console.log(JSON.stringify(mgmtEquipmentResponse));
        setMgmtEquipment(mgmtEquipmentResponse?.mgmt_equipment);

        // CfsInventoryResourceEquipmentsService.cfsInventoryResourceEquipmentsGetExternalSystemByResourceEquipmentIdAndSystemName(data: CfsInventoryResourceEquipmentsGetExternalSystemByResourceEquipmentIdAndSystemNameData): CancelablePromise<CfsInventoryResourceEquipmentsGetExternalSystemByResourceEquipmentIdAndSystemNameResponse>
        /* 
        const req: CfsInventoryResourceEquipmentsGetExternalSystemByResourceEquipmentIdAndSystemNameData = {
           resourceEquipmentId: id,
           systemName: 'TELEMATOR'
         }
         const resp = await CfsInventoryResourceEquipmentsService.cfsInventoryResourceEquipmentsGetExternalSystemByResourceEquipmentIdAndSystemName(req);
         */
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleIconClick = async () => {
    setExpanded((prevExpanded) => !prevExpanded);
    console.log(`Click on equipment ${id}`);
  };

  return (
    <>
      <TableRow key={id} style={{ backgroundColor: "#f0f0f0" }}>
        <TableCell style={{ paddingLeft: "10px" }}>
          {loading ? <CircularProgress /> : null}
          {expanded && !loading ? (
            <ExpandLessIcon onClick={handleIconClick} />
          ) : (
            <ExpandMoreIcon onClick={handleIconClick} />
          )}
        </TableCell>
        <TableCell style={{ paddingLeft: "30px" }}>
          <LanIcon style={{ color: "blue" }} />
        </TableCell>
        <TableCell>
          <div>Host name: {resourceEquipment?.host_name}</div>
          <div>Management IP: {mgmtEquipment?.mgmt_ip} </div>
          <div>Loopback IP: {resourceEquipment?.loopback_ip} </div>
          {resourceEquipment?.external_ids && (
            <Box>
              <Divider />
              External system id's:
              {resourceEquipment?.external_ids.map((id, index) => (
                <div key={index}>
                  {id.system_name} : {id.external_id}
                </div>
              ))}
            </Box>
          )}
        </TableCell>
        <TableCell>
          <div>Vendor: {mgmtEquipment?.vendor}</div>
          <div>Model: {mgmtEquipment?.model}</div>
          <div>Software Version: {mgmtEquipment?.software_version}</div>
          <Divider />
          <div>State: {resourceEquipment?.state}</div>
          <Divider />
          Capabilities:
          {resourceEquipment?.capability?.map((capability, index) => (
            <div key={index}>{capability}</div>
          ))}
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
      {portList && expanded && (
        <>
          {portList.map((port) => (
            <TableRow key={port.id} style={{ backgroundColor: "#f8f8f8" }}>
              <TableCell></TableCell>
              <TableCell style={{ paddingLeft: "50px" }}>
                <SettingsEthernetIcon
                  style={{ color: getIconColor(port.state) }}
                />
              </TableCell>
              <TableCell>{port.display_name}</TableCell>
              <TableCell>{port.state}</TableCell>
              <TableCell>{port.reservation_ext_reference}</TableCell>
            </TableRow>
          ))}
        </>
      )}
    </>
  );
};

export const EquipmentSummaryPortal = ({ name, id, portalPosition }) => {
  const [isPortalReady, setPortalReady] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    // Check if the portal-root exists, and if not, create it
    let portalRoot = document.getElementById("equipment-portal-root");
    if (!portalRoot) {
      portalRoot = document.createElement("div");
      portalRoot.id = "equipment-portal-root";
      document.body.appendChild(portalRoot);
    }
    setPortalReady(true);

    // Cleanup function to remove the portal-root when the component unmounts
    return () => {
      portalRoot?.remove();
    };
  }, []);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  // Conditionally render the portal content only when the portal-root is ready
  return (
    <div>
      <div onClick={toggleExpand}>
        {name}
        {isExpanded ? (
          <ArrowDropUp onClick={toggleExpand} />
        ) : (
          <ArrowDropDown onClick={toggleExpand} />
        )}
      </div>
      {isExpanded ? (
        <div
          style={{
            position: "absolute", // Use 'fixed' to make it float relative to the viewport
            left: `${portalPosition.left}px`, // Position horizontally based on the calling element
            top: `${portalPosition.top}px`, // Position vertically just below the calling element
            backgroundColor: "white", // Background color for visibility
            padding: "10px", // Some padding around the content
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Optional: add a shadow for better visibility
            zIndex: 1000, // Ensure the portal content is above other elements
          }}
        >
          <Equipment id={id} />
        </div>
      ) : null}
    </div>
  );
};

export const EquipmentSummaryWithPortal = ({ name, id }) => {
  const [portalPosition, setPortalPosition] = useState({ left: 0, top: 0 });
  const divRef = useRef<HTMLDivElement>(null);

  const updatePortalPosition = () => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect();
      const style = window.getComputedStyle(divRef.current);
      const fontSize = parseFloat(style.fontSize); // Gets the font size in pixels
      setPortalPosition({
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY + 1.8 * fontSize,
      });
    }
  };

  useEffect(() => {
    // Attach resize event listener
    window.addEventListener("resize", updatePortalPosition);

    // Initial position update
    updatePortalPosition();

    // Clean up function to remove event listener
    return () => {
      window.removeEventListener("resize", updatePortalPosition);
    };
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

  return (
    <div ref={divRef}>
      <EquipmentSummaryPortal
        name={name}
        id={id}
        portalPosition={portalPosition}
      />
    </div>
  );
};

export default Equipment;
