import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import { OpenAPI } from "../gen/cfsInventoryClient/core/OpenAPI";
import { CfsInventoryPortsService } from "../gen/cfsInventoryClient/services.gen";
import { CfsInventoryPortsPortsOnAddressIdResponse, CfsInventoryPortsPortsOnAddressIdData } from '../gen/cfsInventoryClient/types.gen';
import { addAuthorizationHeader, acquireAccessToken } from "../utils/auth";
import { inventoryConfig } from "../authConfig";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination, Box, backdropClasses } from '@mui/material';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import HomeIcon from '@mui/icons-material/Home';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Point from './Point';

//OpenAPI.TOKEN = acquireAccessToken;
OpenAPI.BASE = inventoryConfig.baseUrl;

interface PointsOnAddressProps {
  addressId: string;
  key: string;
  streetAddress: string;
  postalNumber: string;
  postalPlace: string;
}


OpenAPI.interceptors.response.use((response) => {
  if (response.status === 200) {
    console.log(`request to ${response.url} succeeded`)
  }
  return response;
});

const PointsOnAddress: React.FC<PointsOnAddressProps> = ({ addressId, streetAddress, postalNumber, postalPlace }) => {
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [portList, setPortList] = useState<CfsInventoryPortsPortsOnAddressIdResponse | null>(null);

  const handleIconClick = async () => {
    setLoading(true);
    setExpanded(true);
    try {
      console.log(`Click on adressId ${addressId}`);
      const token = await acquireAccessToken();
      OpenAPI.TOKEN = token;

      const request: CfsInventoryPortsPortsOnAddressIdData = {
        uniqueAddressId: addressId
      }

      const response = await CfsInventoryPortsService.cfsInventoryPortsPortsOnAddressId(request) as CfsInventoryPortsPortsOnAddressIdResponse;
      setPortList(response?.points)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <><TableRow key={addressId}>
      <TableCell style={{ paddingLeft: '10px' }}>
        {loading ? <CircularProgress /> : null}
        {expanded && !loading ? <ExpandLessIcon onClick={() => setExpanded(false)} /> : <ExpandMoreIcon onClick={handleIconClick} />}
      </TableCell>
      <TableCell>{addressId}</TableCell>
      <TableCell>{streetAddress}</TableCell>
      <TableCell>{postalNumber}</TableCell>
      <TableCell>{postalPlace}</TableCell>
    </TableRow>
      {portList && expanded && (
        <>
          {portList.map((point) => (
            <Point key={point.id} id={point.id} display_name={point.display_name} />
          ))}
        </>

      )}
    </>
  );
};

export default PointsOnAddress;