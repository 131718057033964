// auth.ts
import { PublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { msalConfig, inventoryConfig } from "../authConfig";
import { OpenAPI } from "../gen/cfsInventoryClient/core/OpenAPI"

type Resolver<T> = (options: any) => Promise<T>;

export async function acquireAccessToken(scope: Array<string> = inventoryConfig.scope) {
  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();
  await msalInstance.handleRedirectPromise(); // This will no longer throw this error since initialize completed before this was invoked

  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
    * User is not signed in. Throw error or wait for user to login.
    * Do not attempt to log a user in outside of the context of MsalProvider
    */
  }
  const request = {
    scopes: scope,
    account: activeAccount || accounts[0]
  };

  const authResult = await msalInstance.acquireTokenSilent(request);

  return authResult.accessToken;
}

export const addAuthorizationHeader = async (request: OpenAPI.Request) => {
  console.log("addAuthorizationHeader");
  if (request.headers) {
    const token = await acquireAccessToken();
    request.headers['Authorization'] = `Bearer ${token}`;
    console.log(`setting bearer token on request`)
  }

  return request;
};